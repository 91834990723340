import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Helmet>
      <body className="page-404" />
    </Helmet>
    <section
          className="banner-area"
          id="homepage-banner-section"
        >
          <div className="container banner-container"
            style={{
              backgroundImage:
                "url(" +
                "https://gbdevpress.enerbank.com/wp-content/uploads/2024/04/home-hero-horizontal.jpg" +
                ")",
            }}>
            <div className="relative banner-area-text-container">
              <div className="container text-center">
                <div className="identifier-container row-fluid">
                  <div className="element span12">
                    <div className="rhif-identifier span5">
                      <h4>HOME IMPROVEMENT FINANCING</h4>
                    </div>
                    <div className="right-angle span2"></div>
                  </div>
                </div>
                <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                  404<br /> <span>Oops! We can&#39;t find that page.</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="container text-center mobile">
            <h1 className="text-white text-left" style={{ padding: "80 0" }}>
              404<br /> <span>Oops! We can&#39;t find that page.</span>
            </h1>
          </div>
        </section>
    <section className="paragraph-text-block">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="pb-5">
              You can try again or check out these popular destinations on our
              website below:
            </h2>
          </div>
        </div>
        </div>
        <div className="container-lg cards-404">
            <div className="icon-cards row justify-content-center">
            <div className="col-md-2">
              <Link className="card-link" to="/homeowner/#howtopay">
                  <div className="card">         
                      <div className="card-image make-payment">
                          <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/06/make-payment-1.png" className="" alt="Make a payment" />
                      </div>
                      <div className="card-body">
                          <h3>Make a Payment <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                      </div>
                  </div>
                </Link>
            </div>
            <div className="col-md-2">
              <Link className="card-link" to="/loan-programs/">
                  <div className="card">         
                      <div className="card-image">
                          <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/07/several-payment-options.png" className="" alt="loan programs" />
                      </div>
                      <div className="card-body">
                          <h3>Loan Programs <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                      </div>
                  </div>
                </Link>
            </div>
            <div className="col-md-2">
              <Link className="card-link" to="/blog/">
                  <div className="card blog">         
                      <div className="card-image">
                          <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/08/blog_regions.png" className="" alt="Blog" />
                      </div>
                      <div className="card-body">
                          <h3>Our Blog <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                      </div>
                  </div>
                </Link>
            </div>
            <div className="col-md-2">
              <Link className="card-link" to="/contact/">
                  <div className="card contact">         
                      <div className="card-image">
                          <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/08/pin-drop.png" className="" alt="Contact" />
                      </div>
                      <div className="card-body">
                          <h3>Contact Us <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                      </div>
                  </div>
                </Link>
            </div>
            <div className="col-md-2">
              <Link className="card-link" to="/">
                  <div className="card">         
                      <div className="card-image">
                          <img src="https://gbdevpress.enerbank.com/wp-content/uploads/2024/08/house-check.png" className="" alt="Homepage" />
                      </div>
                      <div className="card-body">
                          <h3>Homepage <FontAwesomeIcon className="fa-icon" icon="chevron-right" /></h3>
                      </div>
                  </div>
                </Link>
            </div>
            {/* <ul className="eb-green fa-ul">
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon className="fa-icon" icon="credit-card" />
                </span>
                Are you trying to make a payment?{" "}
                <a href="https://enerbank.com/homeowner/" rel="noopener">
                  {" "}
                  Click here
                </a>
              </li>
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon className="fa-icon" icon="layer-group" />
                </span>
                Would you like to learn more about our loan programs?{" "}
                <a
                  href="https://enerbank.com/contractor-financing/"
                  rel="noopener"
                >
                  {" "}
                  Click here
                </a>
              </li>
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon className="fa-icon" icon="blog" />
                </span>
                Would you like to see our latest updates and helpful articles?
                Check out our blog{" "}
                <a href="https://enerbank.com/blog/" rel="noopener">
                  {" "}
                  here
                </a>
              </li>
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon
                    className="fa-icon"
                    icon="phone"
                    flip="horizontal"
                  />
                </span>
                Would you like to contact us? Click{" "}
                <a href="https://enerbank.com/contact/" rel="noopener">
                  {" "}
                  Click here
                </a>
              </li>
              <li>
                <span className="fa-li">
                  <FontAwesomeIcon className="fa-icon" icon="house-chimney" />
                </span>
                Or visit our homepage{" "}
                <a href="https://enerbank.com/" rel="noopener">
                  {" "}
                  here
                </a>
              </li>
            </ul> */}
          
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
